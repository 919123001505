/* eslint-disable react-hooks/exhaustive-deps */
/**
 * This page shows a table of all users. It is only available for Admin users.
 *
 * Admin users can add one or more users, or edit a user.
 */

// React main
import React, { useState, useCallback, useEffect, useMemo, HTMLProps } from 'react';

// Icons
import {
  SearchIcon,
  PlusIcon,
  ViewGridAddIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from '@heroicons/react/solid';
import { CogIcon } from '@heroicons/react/outline';

// API - Redux
import { useUsers, User } from 'src/services/users';
import { useAuth } from 'src/services/auth';
import { useApps } from 'src/services/apps';

// Regular Table
// import { Table } from 'src/components';
import { debounce } from 'lodash';

// User Table
import {
  // Column,
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  SortingState,
  // Table,
  useReactTable,
} from '@tanstack/react-table';

import { MultiEditUserModal } from 'src/components';

// Local components
import { UserModal } from '../../components/UserModal';
import { MultipleUsersModal } from './components';

// ///////////////////////////////////////////

export const Users: React.FC = () => {
  // const [selectedRowsIds, setSelectedRowsIds] = useState({});
  const [configureModal, setConfigureModal] = useState(false);
  const [multipleUsersModal, setMultipleUsersModal] = useState(false);
  const [multiEditUserModal, setMultiEditUserModal] = useState(false);
  const [userId, setUserId] = useState(null);
  const [multiUserIds, setMultiUserIds] = useState(null);
  const [search, setSearch] = useState('');
  const { users, loadUsers } = useUsers();
  const { isAdmin } = useAuth();

  const { apps, loadApps } = useApps();
  useEffect(() => {
    loadApps();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearch = (event: any) => {
    setSearch(event.target.value);
  };

  const debouncedSearch = useCallback(debounce(handleSearch, 250), []);

  useEffect(() => {
    loadUsers();

    return () => {
      debouncedSearch.cancel();
    };
  }, []);

  const filterSearch = useMemo(() => {
    return users.filter(
      (item: any) =>
        item.email?.toLowerCase().includes(search.toLowerCase()) ||
        item.name?.toLowerCase().includes(search.toLowerCase()),
    );
  }, [search, users]);

  const configureModalOpen = (id: any) => {
    setUserId(id);
    setConfigureModal(true);
  };

  const multiEditUserModalOpen = (ids: any) => {
    setMultiUserIds(ids);
    setMultiEditUserModal(true);
  };

  const configureModalClose = () => setConfigureModal(false);

  const multipleUsersModalClose = () => setMultipleUsersModal(false);

  const multiEditUserModalClose = () => setMultiEditUserModal(false);

  // const selectedRows = useCallback((rows: Record<string, boolean>) => {
  //   setSelectedRowsIds(rows);
  // }, []);

  // ////////////////////////
  // New Table Start
  // ////////////////////////

  function IndeterminateCheckbox({
    indeterminate,
    ...rest
  }: { indeterminate?: boolean } & HTMLProps<HTMLInputElement>) {
    const ref = React.useRef<HTMLInputElement>(null!);

    React.useEffect(() => {
      if (typeof indeterminate === 'boolean') {
        ref.current.indeterminate = !rest.checked && indeterminate;
      }
    }, [ref, indeterminate]);

    return (
      <input
        type="checkbox"
        ref={ref}
        className="focus:ring-primary-800 h-4 w-4 text-primary-700 border-gray-300 rounded cursor-pointer"
        {...rest}
      />
    );
  }
  function CreateUserTable() {
    const [rowSelection, setRowSelection] = React.useState({});
    const [sorting, setSorting] = React.useState<SortingState>([]);
    const userColumns = React.useMemo<ColumnDef<User>[]>(
      () => [
        {
          id: 'select',
          header: ({ table }) => (
            <IndeterminateCheckbox
              {...{
                checked: table.getIsAllRowsSelected(),
                indeterminate: table.getIsSomeRowsSelected(),
                onChange: table.getToggleAllRowsSelectedHandler(),
              }}
            />
          ),
          cell: ({ row }) => (
            <div className="flex items-center">
              <IndeterminateCheckbox
                {...{
                  checked: row.getIsSelected(),
                  disabled: !row.getCanSelect(),
                  indeterminate: row.getIsSomeSelected(),
                  onChange: row.getToggleSelectedHandler(),
                }}
              />
            </div>
          ),
        },
        {
          header: 'Name',
          footer: (props) => props.column.id,
          accessorKey: 'name',
        },
        {
          header: 'Email',
          footer: (props) => props.column.id,
          accessorKey: 'email',
        },
        {
          header: 'Status',
          footer: (props) => props.column.id,
          accessorKey: 'status',
        },
        {
          header: ' ',
          cell: (props: any) => {
            const { row } = props;

            if (isAdmin) {
              return (
                <div className="text-right relative">
                  <div className="absolute inline-flex px-2 py-1 text-transparent items-center font-medium border border-transparent right-0 z-0">
                    Configure <CogIcon className="-mr-0.5 ml-2 h-4 w-4 text-gray-500" />
                  </div>
                  <button
                    onClick={() => configureModalOpen(row.original.id)}
                    type="button"
                    className="relative z-10 opacity-0 group-hover:opacity-100 transition-opacity inline-flex items-center px-2 py-1 border border-gray-200 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                  >
                    Configure <CogIcon className="-mr-0.5 ml-2 h-4 w-4" />
                  </button>
                </div>
              );
            }

            return null;
          },
        },
      ],
      [isAdmin],
    );

    const table = useReactTable({
      data: filterSearch,
      columns: userColumns,
      state: {
        rowSelection,
        sorting,
      },
      initialState: {
        pagination: {
          pageSize: 10,
        },
      },
      onSortingChange: setSorting,
      getSortedRowModel: getSortedRowModel(),
      enableRowSelection: true, // enable row selection for all rows
      onRowSelectionChange: setRowSelection,
      getCoreRowModel: getCoreRowModel(),
      getFilteredRowModel: getFilteredRowModel(),
      getPaginationRowModel: getPaginationRowModel(),
      debugTable: false, // make true if needed
    });

    return (
      <>
        <div className="flex justify-between w-100 my-3 items-center mb-5 ">
          <div className="flex items-center">
            <div className="inline-block">
              <label htmlFor="email" className="block text-sm font-medium text-gray-700 sr-only">
                Search candidates
              </label>
              <div className="mt-1 flex rounded-md shadow-sm">
                <div className="relative flex items-stretch flex-grow focus-within:z-10">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <SearchIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                  </div>
                  <input
                    type="text"
                    name="email"
                    id="email"
                    className="focus:ring-primary-500 focus:border-primary-500 block w-full rounded-md pl-10 sm:text-sm border-gray-200"
                    placeholder="Search everything..."
                    onChange={debouncedSearch}
                  />
                </div>
              </div>
            </div>
          </div>

          {Object.keys(rowSelection).length !== 0 && (
            <div className="flex items-center">
              <button
                onClick={() => multiEditUserModalOpen(table.getSelectedRowModel().flatRows)}
                type="button"
                className="inline-flex items-center px-4 py-2 text-sm font-medium rounded-md text-gray-500 bg-primary-50 hover:bg-primary-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 border border-gray-200 shadow-sm"
              >
                <CogIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
                Configure {Object.keys(rowSelection).length} user{Object.keys(rowSelection).length !== 1 ? 's' : null}
              </button>
            </div>
          )}
        </div>
        <div className="flex justify-between items-center text-xs font-medium text-gray-500 uppercase tracking-wider">
          <div className="py-3 text-left ">
            Showing {table.getRowModel().rows.length} of {table.getCoreRowModel().rows.length} entries
          </div>
          <nav className="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
            <button
              className="relative inline-flex items-center rounded-l-md px-2 py-2 ring-1 ring-inset ring-gray-300 transition hover:bg-gray-100 focus:z-20 focus:outline-offset-0"
              onClick={() => table.setPageIndex(0)}
              disabled={!table.getCanPreviousPage()}
            >
              <ChevronDoubleLeftIcon className="w-4 h-4" />
            </button>
            <button
              className="relative inline-flex items-center px-2 py-2 ring-1 ring-inset ring-gray-300 transition hover:bg-gray-100 focus:z-20 focus:outline-offset-0"
              onClick={() => table.previousPage()}
              disabled={!table.getCanPreviousPage()}
            >
              <ChevronLeftIcon className="w-4 h-4" />
            </button>
            <div className="relative inline-flex items-center px-2 py-2 ring-1 ring-inset ring-gray-300  focus:z-20 focus:outline-offset-0">
              <span>
                Page {table.getState().pagination.pageIndex + 1} of {table.getPageCount()}
              </span>
            </div>
            <button
              className="relative inline-flex items-center px-2 py-2 ring-1 ring-inset ring-gray-300 transition hover:bg-gray-100 focus:z-20 focus:outline-offset-0"
              onClick={() => table.nextPage()}
              disabled={!table.getCanNextPage()}
            >
              <ChevronRightIcon className="w-4 h-4" />
            </button>
            <button
              className="relative inline-flex items-center rounded-r-md px-2 py-2 ring-1 ring-inset ring-gray-300 transition hover:bg-gray-100 focus:z-20 focus:outline-offset-0"
              onClick={() => table.setPageIndex(table.getPageCount() - 1)}
              disabled={!table.getCanNextPage()}
            >
              <ChevronDoubleRightIcon className="w-4 h-4" />
            </button>
          </nav>
          <div className="flex items-center gap-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            <span>Results per page</span>
            <select
              value={table.getState().pagination.pageSize}
              className="focus:ring-primary-500 focus:border-primary-500 py-1 block rounded-md text-sm border-gray-200"
              onChange={(e) => {
                table.setPageSize(Number(e.target.value));
              }}
            >
              {[10, 20, 50, 100, 250, 500, 10000, table.getCoreRowModel().rows.length].map((pageSize) =>
                pageSize <= table.getCoreRowModel().rows.length ? (
                  <option key={pageSize} value={pageSize}>
                    {pageSize === table.getCoreRowModel().rows.length ? `all` : `${pageSize}`}
                  </option>
                ) : null,
              )}
            </select>
          </div>
        </div>
        <div className="shadow border-b border-gray-200 sm:rounded-lg overflow-hidden">
          <table className="min-w-full divide-y divide-gray-200 table-auto">
            <thead className="bg-gray-50">
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    return (
                      <th
                        key={header.id}
                        colSpan={header.colSpan}
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                      >
                        {header.isPlaceholder ? null : (
                          <div
                            {...{
                              className: header.column.getCanSort() ? 'flex items-center' : '',
                              onClick: header.column.getToggleSortingHandler(),
                            }}
                          >
                            <span> {flexRender(header.column.columnDef.header, header.getContext())}</span>
                            {{
                              asc: <ChevronUpIcon className="w-4 h-4 text-gray-400 ml-1" />,
                              desc: <ChevronDownIcon className="w-4 h-4 text-gray-400 ml-1" />,
                            }[header.column.getIsSorted() as string] ?? null}
                          </div>
                        )}
                      </th>
                    );
                  })}
                </tr>
              ))}
            </thead>
            <tbody className="">
              {table.getRowModel().rows.map((row, rowIndex) => {
                return (
                  <tr
                    key={row.id}
                    role="row"
                    className={
                      rowIndex % 2 === 0
                        ? 'bg-white group border-l-4 border-transparent transition hover:border-primary-600'
                        : 'bg-gray-50 group border-l-4 border-transparent transition hover:border-primary-600'
                    }
                  >
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <td
                          key={cell.id}
                          className={
                            cell.id.substring(2) === 'select'
                              ? 'w-4 px-6 py-4 whitespace-nowrap text-sm text-gray-500'
                              : 'px-6 py-4 whitespace-nowrap text-sm text-gray-500'
                          }
                        >
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
          {/* Debugging buttons below, uncomment if needee */}
          {/* <hr />
          <br />
          <div>
            <button className="border rounded p-2 mb-2" onClick={() => console.info('rowSelection', rowSelection)}>
              Log `rowSelection` state
            </button>
          </div>
          <div>
            <button
              className="border rounded p-2 mb-2"
              onClick={() => console.info('table.getSelectedRowModel().flatRows', table.getSelectedRowModel().flatRows)}
            >
              Log table.getSelectedRowModel().flatRows
            </button>
          </div> */}
        </div>
      </>
    );
  }

  // ////////////////////////
  // New Table End
  // ////////////////////////
  return (
    <div className="relative">
      <div className="max-w-7xl mx-auto py-4 px-3 sm:px-6 lg:px-8 h-full flex-grow">
        <div className="pb-5 mt-6 border-b border-gray-200 sm:flex sm:items-center sm:justify-between">
          <h1 className="text-3xl leading-6 font-bold text-gray-900">Users</h1>

          {isAdmin && (
            <div className="mt-3 sm:mt-0 sm:ml-4">
              <button
                onClick={() => configureModalOpen(null)}
                type="button"
                className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-700 hover:bg-primary-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-800 mx-5 "
              >
                <PlusIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
                Add new user
              </button>
              <button
                onClick={() => setMultipleUsersModal(true)}
                type="button"
                className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-700 hover:bg-primary-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-800"
              >
                <ViewGridAddIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
                Add new users
              </button>
            </div>
          )}
        </div>

        {/* <div className="flex flex-col">
          <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
              <div className="shadow border-b border-gray-200 sm:rounded-lg overflow-hidden">
                <Table
                  data={filterSearch as any}
                  columns={columns}
                  getSelectedRowIds={selectedRows}
                  loading={userTableLoading}
                />
              </div>
            </div>
          </div>
        </div> */}

        <div className="flex flex-col">
          <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">{CreateUserTable()}</div>
          </div>
        </div>

        {configureModal && (
          <UserModal
            open={configureModal}
            onClose={configureModalClose}
            userId={userId}
            setUserId={setUserId}
            apps={apps}
          />
        )}
        {multipleUsersModal && (
          <MultipleUsersModal open={multipleUsersModal} onClose={multipleUsersModalClose} apps={apps} />
        )}
        {multiEditUserModal && (
          <MultiEditUserModal
            open={multiEditUserModal}
            onClose={multiEditUserModalClose}
            apps={apps}
            setUserId={setUserId}
            userIds={multiUserIds}
          />
        )}
      </div>
    </div>
  );
};
