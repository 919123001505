import { performApiCall } from 'src/services/api';

// import { SysInfo } from './types';
// import { transformSysInfo } from './transformations';

export const fetchSysInfo = async () => {
  const res = await performApiCall({
    path: `/api/info`,
  });
  return res.data;
};
