import { legacy_createStore as createStore, compose, applyMiddleware, combineReducers } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { reducer as authReducer } from 'src/services/auth';

import usersReducer from 'src/services/users/redux/reducers';
import appsReducer from 'src/services/apps/redux/reducers';
import sysInfoReducer from 'src/services/sysInfo/redux/reducers';
import { State } from './types';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth'],
};

const appReducer = combineReducers<State>({
  auth: authReducer,
  users: usersReducer,
  apps: appsReducer,
  sysInfo: sysInfoReducer,
});

const persistedReducer = persistReducer(persistConfig, appReducer);

const middlewares = [thunkMiddleware];

// @ts-ignore
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const configureStore = () => {
  const store = createStore(persistedReducer, composeEnhancers(applyMiddleware(...middlewares)));

  const persistor = persistStore(store);

  return { store, persistor };
};
