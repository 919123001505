import { useDispatch, useSelector } from 'react-redux';
import {
  getUsers,
  fetchUsers,
  fetchUserById,
  fetchPersonalInfo,
  updateUserById,
  updateMultipleUsers,
  updatePersonalInfo,
  createUser,
  deleteUser,
  clearCurrentUser,
  createBatchUsers,
  fetchRecoveryLink,
  resetTotpById,
} from '../redux';
import { getUserById, getRecoveryLink, getUserModalLoading, getUserslLoading } from '../redux/selectors';

export function useUsers() {
  const dispatch = useDispatch();
  const users = useSelector(getUsers);
  const user = useSelector(getUserById);
  const recoveryLink = useSelector(getRecoveryLink);
  const userModalLoading = useSelector(getUserModalLoading);
  const userTableLoading = useSelector(getUserslLoading);

  function loadUsers() {
    return dispatch(fetchUsers());
  }

  function loadUser(id: string) {
    return dispatch(fetchUserById(id));
  }

  function loadPersonalInfo() {
    return dispatch(fetchPersonalInfo());
  }

  function clearSelectedUser() {
    return dispatch(clearCurrentUser());
  }

  function editUserById(data: any) {
    return dispatch(updateUserById(data));
  }

  function editMultipleUsers(data: any) {
    return dispatch(updateMultipleUsers(data));
  }

  function editPersonalInfo(data: any) {
    return dispatch(updatePersonalInfo(data));
  }

  function createNewUser(data: any) {
    return dispatch(createUser(data));
  }

  function createUsers(data: any) {
    return dispatch(createBatchUsers(data));
  }

  function deleteUserById(id: string) {
    return dispatch(deleteUser(id));
  }
  function getRecoveryLinkUserById(id: string) {
    return dispatch(fetchRecoveryLink(id));
  }

  function resetTotp(id: string) {
    return dispatch(resetTotpById(id));
  }

  return {
    users,
    user,
    recoveryLink,
    loadUser,
    loadUsers,
    loadPersonalInfo,
    editUserById,
    editMultipleUsers,
    editPersonalInfo,
    userModalLoading,
    userTableLoading,
    createNewUser,
    deleteUserById,
    getRecoveryLinkUserById,
    clearSelectedUser,
    createUsers,
    resetTotp,
  };
}
