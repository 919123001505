import React from 'react';
import { CogIcon, PlusCircleIcon } from '@heroicons/react/outline';
import _ from 'lodash';
import { AppStatusEnum } from 'src/services/apps';

export const initialEditorYaml = `luck: except
natural: still
near: though
search:
  - feature
  - - 1980732354.689713
    - hour
    - butter:
        ordinary: 995901949.8974948
        teeth: true
        whole:
          - -952367353
          - - talk: -1773961379
              temperature: false
              oxygen: true
              laugh:
                flag:
                  in: 2144751662
                  hospital: -1544066384.1973226
                  law: congress
                  great: stomach`;

const tableConsts = [
  {
    status: AppStatusEnum.Installed,
    colorClass: 'green-600',
    buttonTitle: 'Configure',
    buttonIcon: <CogIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />,
  },
  {
    status: AppStatusEnum.NotInstalled,
    colorClass: 'gray-600',
    buttonTitle: 'Install',
    buttonIcon: <PlusCircleIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />,
  },
  {
    status: AppStatusEnum.Installing,
    colorClass: 'primary-600',
    buttonTitle: null,
    buttonIcon: null,
  },
  {
    status: AppStatusEnum.External,
    colorClass: 'gray-400',
    buttonTitle: null,
    buttonIcon: null,
  },
];

export const getConstForStatus = (appStatus: AppStatusEnum, paramName: string) => {
  const tableConst = _.find(tableConsts, { status: appStatus });
  return _.get(tableConst, paramName);
};
