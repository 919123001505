/**
 * This page shows information about a single application. It contains several
 * configuration options (that are not implemented in the back-end yet) such as:
 *
 * 1. Toggling auto-updates
 * 2. Advanced configuration by overwriting helm values
 * 3. Deleting the application
 *
 * This page is only available for Admin users.
 */
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm, useWatch } from 'react-hook-form';
import _ from 'lodash';
import { XCircleIcon } from '@heroicons/react/outline';
import { DisableAppForm, useApps } from 'src/services/apps';
import { Modal, Tabs } from 'src/components';
import { Checkbox } from 'src/components/Form';
import { appAccessList } from 'src/components/UserModal/consts';
import { AdvancedTab, GeneralTab } from './components';

export const AppSingle: React.FC = () => {
  const [disableAppModal, setDisableAppModal] = useState(false);
  const [removeAppData, setRemoveAppData] = useState(false);
  const params = useParams();
  const appSlug = params.slug;
  const { app, loadApp, disableApp, clearSelectedApp } = useApps();
  const navigate = useNavigate();

  const initialDisableData = { slug: appSlug, removeAppData: false };

  const { control, reset, handleSubmit } = useForm<DisableAppForm>({
    defaultValues: initialDisableData,
  });

  const removeAppDataWatch = useWatch({
    control,
    name: 'removeAppData',
  });

  useEffect(() => {
    setRemoveAppData(removeAppDataWatch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [removeAppDataWatch]);

  useEffect(() => {
    if (appSlug) {
      loadApp(appSlug);
    }

    return () => {
      clearSelectedApp();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appSlug]);

  if (!app) {
    return null;
  }

  const appImageSrc = _.find(appAccessList, { name: appSlug })?.image;
  const appDocumentationUrl = _.find(appAccessList, { name: appSlug })?.documentationUrl;

  const openDocumentationInNewTab = () => {
    window.open(appDocumentationUrl, '_blank', 'noopener,noreferrer');
  };

  const tabs = [
    {
      name: 'General',
      component: <GeneralTab />,
    },
    { name: 'Advanced Configuration', component: <AdvancedTab /> },
  ];

  const onDisableApp = async () => {
    try {
      await handleSubmit((data) => disableApp(data))();
    } catch (e: any) {
      // Continue
    }
    setDisableAppModal(false);
    clearSelectedApp();
    navigate('/apps');
  };

  const handleCloseDisableModal = () => {
    reset(initialDisableData);
    setDisableAppModal(false);
  };

  return (
    <div className="max-w-7xl mx-auto py-4 sm:px-6 lg:px-8 overflow-hidden lg:flex lg:flex-row">
      <div
        className="block bg-white overflow-hidden shadow rounded-sm basis-4/12 mx-auto sm:px-6 lg:px-8 lg:flex-none"
        style={{ height: 'fit-content' }}
      >
        <div className="px-4 py-5 sm:p-6 flex flex-col">
          <img className="h-24 w-24 rounded-md overflow-hidden mr-4 mb-3" src={appImageSrc} alt={app.name} />
          <div className="mb-3">
            <h2 className="text-2xl leading-8 font-bold">{app.name}</h2>
            <div className="text-sm leading-5 font-medium text-gray-500">Installed on August 25, 2020</div>
          </div>
          <button
            type="button"
            className="mb-3 inline-flex items-center px-4 py-2 shadow-sm text-sm font-medium rounded-md text-yellow-900 bg-yellow-300 hover:bg-yellow-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 justify-center"
            onClick={() => setDisableAppModal(true)}
          >
            <XCircleIcon className="-ml-0.5 mr-2 h-4 w-4 text-yellow-900" aria-hidden="true" />
            Disable App
          </button>
          <button
            type="button"
            className="inline-flex items-center px-4 py-2 border border-gray-200 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 justify-center"
            onClick={openDocumentationInNewTab}
          >
            View manual
          </button>
        </div>
      </div>

      <div className="mx-auto sm:mt-8 lg:mt-0 lg:px-8 overflow-hidden block">
        <div className="bg-white sm:px-6 shadow rounded-sm basis-8/12">
          <div className="px-4 py-5 sm:p-6">
            <Tabs tabs={tabs} />
          </div>
        </div>
      </div>

      {disableAppModal && (
        <Modal
          onClose={handleCloseDisableModal}
          open={disableAppModal}
          onSave={onDisableApp}
          saveButtonTitle={removeAppData ? `Yes, delete  and it's data` : 'Yes, delete'}
          cancelButtonTitle="No, cancel"
          useCancelButton
        >
          <div className="bg-white px-4">
            <div className="space-y-10 divide-y divide-gray-200">
              <div>
                <div>
                  <h3 className="text-lg leading-6 font-medium text-gray-900">Disable app</h3>
                </div>
                <div className="px-4 py-5 sm:p-6">
                  Are you sure you want to disable {app.name}? The app will get uninstalled and none of your users will
                  be able to access the app.
                </div>
                <fieldset className="px-4 py-5 sm:p-6">
                  <div className="relative flex items-start">
                    <div className="flex items-center h-5">
                      <Checkbox control={control} name="removeAppData" id="removeAppData" />
                    </div>
                    <div className="ml-3 text-sm">
                      <label htmlFor="removeAppData" className="font-medium text-gray-700">
                        Remove app data
                      </label>
                      <p id="removeAppData-description" className="text-gray-500">
                        {removeAppData
                          ? `The app's data will be removed. After this operation is done you will not be able to access the app, nor the app data. If you re-install the app, it will have none of the data it had before.`
                          : `The app's data does not get removed. If you install the app again, you will be able to access the data again.`}
                      </p>
                    </div>
                  </div>
                </fieldset>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};
